.hint-icon
    color #ccc
    margin-left 5px
    transition all .3s

    &:hover
        color #666

.popover-title
    font-weight 500

.fix-popover
    position relative
    border none